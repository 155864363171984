function urlParamsStringIntoParamsObject(keyValuesStr) {
  let paramsObject = {}

  if (typeof keyValuesStr === 'string' && keyValuesStr) {
    let keyValuesStrModified =
      keyValuesStr[0] === '?' ? keyValuesStr.substr(1) : keyValuesStr

    keyValuesStrModified.split('&').forEach((keyValueStringPair) => {
      let [key, value] = keyValueStringPair.split('=')
      if (key && value) {
        paramsObject[key] = value
      }
    })
  }

  return paramsObject
}

export default urlParamsStringIntoParamsObject
